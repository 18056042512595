(function () {
  "use strict";

  $(function () {
    const swiper = new Swiper(".js-top-fv", {
      loop: true,
      speed: 800,
      autoplay: {
        delay: 5000,
      },
      pagination: {
        el: ".swiper-pagination",
        // type: "progressbar",
      },
      breakpoints: {
        1024: {
          slidesPerView: 1.14893,
          spaceBetween: 40,
        },
      },
      on: {
        init: function () {
          this.slides[0].classList.add("-custom-active");

          setTimeout(function () {
            $(".swiper-pagination-bullet").first().addClass("-custom-first-active");
          }, 300);
        },
        slideChangeTransitionEnd: function () {
          this.slides.forEach(function (slide) {
            slide.classList.remove("-custom-active");
          });
          this.slides[this.activeIndex].classList.add("-custom-active");
          $(".swiper-pagination-bullet").removeClass("-custom-first-active");
          $(".swiper-pagination-bullet").addClass("-custom-active");
        },
      },
    });

    function checkVisibility() {
      $(".js-top-fv-target").each(function () {
        const $this = $(this);
        const windowTop = $(window).scrollTop();
        const elementTop = $this.offset().top;
        const elementHeight = $this.outerHeight();

        if (windowTop > elementTop + elementHeight || windowTop + $(window).height() < elementTop) {
          if (!$this.hasClass("-active")) {
            $(".js-top-fv-button").addClass("-active");
          }
        } else {
          $(".js-top-fv-button").removeClass("-active");
        }
      });
    }

    $(window).on("scroll resize", checkVisibility);
    checkVisibility();
  }); // END
})();
